import React from 'react';
import { Row, Col } from 'react-bootstrap';

function OrganizationSettingsDisplay({ organization, licenseLevelStrings, licenseTypeStrings }) {
    return (
        <>
            <Row className="mb-3">
                <Col className="align-self-end text-truncate mb-4">
                    <h4>{organization.organizationName}</h4>
                </Col>
            </Row>

            <Row className="mb-3">
                <Col className="font-weight-bold" lg={4}>
                    License Details
                </Col>
                <Col>
                    {licenseLevelStrings[organization.licenseLevel]}, {licenseTypeStrings[organization.licenseType]}
                </Col>
            </Row>
        </>
    )
}

export default OrganizationSettingsDisplay
