import React from "react";
import { Modal, Button, Spinner } from "react-bootstrap";

function DeleteConfirmation({ showModal, hideModal, loading, confirmDeletion, message }) {
  return (
    <Modal
      show={showModal}
      onHide={hideModal}
      centered
    >
      <Modal.Header className="modal__header">
        <span>Confirm Deletion</span>
        <span
          className="modal__header__btn"
          onClick={hideModal}
          aria-label="Exit"
        >
          X
        </span>
      </Modal.Header>

      <Modal.Body>
        <div className="d-flex justify-content-center">
          <p style={{ textAlign: "center" }}>{message}</p>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outline-primary" onClick={hideModal}>Cancel</Button>
        <Button variant="danger" onClick={confirmDeletion}>{loading ?
          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          : "Delete"}
        </Button>
      </Modal.Footer>

    </Modal>

  )

}

export default DeleteConfirmation;
