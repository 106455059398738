import React from 'react';
import { Form, Col } from 'react-bootstrap';

import PickListSelect from '../../shared/asyncSelect/PickListSelect';

function ClientGroupsForServiceForm({ clientGroupList, currentGroupId, updateGroupId, availableClients, selectedClients, removeClientsFromList, addToClientList, ...props }) {
    return (
        <>
            <Form.Row>
                <Form.Group as={Col}>
                    <Form.Label>Select Client Group</Form.Label>
                    <Form.Control
                        as="select"
                        placeholder="Select a Client Group to apply to this Service Activity"
                        className={`sure-impact__select ${currentGroupId === 0 ? 'sure-impact__select-default' : ''}`}
                        value={currentGroupId}
                        name="clientGroupId"
                        onChange={(e) => updateGroupId(e)}
                    >
                        <option value={0}>Select a Group</option>
                        {clientGroupList.map(group => (
                            <option key={group.id} value={group.id}>{group.name}</option>
                        ))}
                    </Form.Control>
                </Form.Group>
            </Form.Row>

            {currentGroupId > 0 ?
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label className="mb-0">
                            Add/Remove Clients in the group to apply to this Service Activity
                        </Form.Label>
                        <PickListSelect
                            source={availableClients}
                            target={selectedClients}
                            removeFromTarget={removeClientsFromList}
                            addToTarget={addToClientList}
                        />
                        <Form.Control.Feedback type="invalid" className={props.touched.additionalClients && !!props.errors.additionalClients ? 'd-block' : null}>
                            {props.errors.additionalClients}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>
                : null}
        </>
    )
}

export default ClientGroupsForServiceForm;