import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Toast } from 'primereact/toast';

import { getAnalyticsById, deleteAnalytics } from '../../scripts/api/analyticsApi';
import ExternalAnalyticsDto from '../../DTOs/PowerBiEmbedded/ExternalAnalyticsDto';

import useToast from '../shared/hooks/useToast';
import DeleteConfirmation from '../shared/DeleteConfirmation';

import DisplayExternalAnalytics from './DisplayExternalAnalytics';
import EditExternalAnalytics from './EditExternalAnalytics';

function ExternalAnalyticsPage() {
    const [externalAnalytics, setExternalAnalytics] = useState(new ExternalAnalyticsDto());
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();
    const { toast, errorToast } = useToast();

    const fetchExternalAnalytics = useCallback(async (analyticsId) => {
        try {
          let result = await getAnalyticsById(analyticsId);
          setExternalAnalytics(result);
        } catch (error) {
          errorToast(`Error occurred: ${error}`);
        }
    }, [])

    const handleBackClick = () => {
        navigate("/ExternalAnalytics");
    }

    const handleEditClick = () => {
        setShowEditModal(true);
    }

    const handleDeleteClick = () => {
        setShowDeleteModal(true);
    }

    const handleErrorMessage = (message) => {
      errorToast(message);
    }

    const handleDeleteExternalAnalytics = async () => {
      setIsLoading(true)
      try {
        await deleteAnalytics(id);
        navigate(`/ExternalAnalytics/`);

      } catch (error) {
          errorToast(`Error occurred while deleting the external analytics: ${error}`)
      } finally {
          setShowDeleteModal(false);
          setIsLoading(false);
      }
    }

    useEffect(() => {
        fetchExternalAnalytics(id);
    }, [fetchExternalAnalytics, id])

    return (
        <>
            <DisplayExternalAnalytics
                externalAnalytics={externalAnalytics}
                handleBackClick={handleBackClick}
                handleEditClick={handleEditClick}
                handleDeleteClick={handleDeleteClick}

            />

          {showEditModal ?
            <EditExternalAnalytics
              showModal={showEditModal}
              hideModal={() => setShowEditModal(false)}
              handleRefresh={fetchExternalAnalytics}
              externalAnalytics={externalAnalytics}
              handleErrorMessage={handleErrorMessage }
                  />
                  : null}

            {showDeleteModal ?
                <DeleteConfirmation
                    showModal={showDeleteModal}
                    hideModal={() => setShowDeleteModal(false)}
                    loading={isLoading}
                    confirmDeletion={handleDeleteExternalAnalytics}
                    message={"Are you sure that you want to delete this external analytics? This action is irreversable"}
                />
          : null}

          <Toast ref={toast} position="bottom-left" />
        </>
    )
}

export default ExternalAnalyticsPage
