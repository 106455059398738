import React from 'react';
import { Container, Row, ButtonToolbar, Button } from 'react-bootstrap';

function DisplayExternalAnalytics({ externalAnalytics, handleBackClick,
  handleEditClick, handleDeleteClick }) {

  return (
    <Container className="basic-card pb-3"> 
      <Row className="mb-3 ml-3">
        <h5>External Analytics Configuration</h5>
      </Row>
      <Row className="ml-3">
        Display Name: {externalAnalytics.displayName}
      </Row>
      <Row className="ml-3">
        External ID: {externalAnalytics.externalId}
      </Row>
      <Row className="ml-3 pb-3">
        Replaces Default Analytics: {externalAnalytics.replacesDefaultAnalytics ? "Yes" : "No"}
      </Row>
      <ButtonToolbar className="justify-content-between">
        <Button variant="outline-primary" size="sm" onClick={handleBackClick}>Back</Button>
        <div md={8} className="d-flex justify-content-between">
          <Button
            size="sm"
            variant="outline-primary"
            className="mr-3"
            onClick={handleEditClick}
          >Edit</Button>
          <Button
            onClick={handleDeleteClick}
            size="sm"
            variant="outline-primary"
            className="mr-3"
          >Delete</Button>
        </div>
      </ButtonToolbar>
    </Container>
  )
}

export default DisplayExternalAnalytics
