import React from 'react';
import { Form, Row, Col, ButtonToolbar, Button, Spinner } from 'react-bootstrap';

function ToggleAnalyticsForm({ handleSubmit, defaultAnalyticsId, handleAnalyticsChange, externalAnalyticsDropdownItems, isLoading }) {
    return (
        <Form onSubmit={handleSubmit} className="mt-">
            <Row className="mb-3">
                <Col className="font-weight-bold" lg={4}>
                    <h5>Update Analytics Tab</h5>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Form.Label>Select Analytics Tab</Form.Label>
                    <Form.Control
                        as="select"
                        name="defaultAnalyticsId"
                        value={defaultAnalyticsId}
                        onChange={handleAnalyticsChange}
                        md={6}
                    >
                        {externalAnalyticsDropdownItems.map(analytics => (
                            <option key={analytics.text} value={analytics.value}>{analytics.text}</option>
                        ))}
                    </Form.Control>
                </Col>
            </Row>


            <ButtonToolbar className="mt-4 justify-content-end">
                <Button variant="primary" disabled={isLoading} type="submit">
                    {isLoading ? (
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    ) : (
                        "Save"
                    )}
                </Button>
            </ButtonToolbar>
        </Form>
    )
}

export default ToggleAnalyticsForm
