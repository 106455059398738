import { apiCall } from "../api";
import ClientGroupListDto from "../../DTOs/ClientGroup/ClientGroupListDto";
import ClientGroupWithClientsDto from "../../DTOs/ClientGroup/ClientGroupWithClientsDto";

export const getAllClientGroups = async (pageIndex, pageSize) => {
    let url = `clientgroup?&pageindex=${pageIndex}`;

    if (pageSize !== undefined && pageSize !== null) {
        url += `&pagesize=${pageSize}`;
    }

    let response = await apiCall(url);
    return new ClientGroupListDto(response.result);
}

export const getClientGroupById = async(groupId) => {
    const url = `clientgroup/${groupId}`;
    let response = await apiCall(url);
    return new ClientGroupWithClientsDto(response.result);
}

export const createClientGroup = async (groupDto) => {
    const url = "clientgroup/addclientgroup";
    let response = await apiCall(url, { method: "POST", body: groupDto });
    return response.result;
}

export const updateClientGroup = async (groupDto) => {
    const url = "clientgroup/updateclientgroup";
    let response = await apiCall(url, { method: "PUT", body: groupDto });
    return response.result;
}

export const deleteClientGroup = async (groupId) => {
    const url = `clientgroup/${groupId}`;
    let response = await apiCall(url, { method: "DELETE" });
    return response;
}