export default class UpdateClientGroupDto {
    constructor(options = {}) {
        this.id = options.id || 0;
        this.name = options.name || "";
        this.isActive = options.isActive;
        this.clientIds = options.clientIds || [];
    }

    stringify() {
        return JSON.stringify(this);
    }
}