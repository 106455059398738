import React, { useEffect, useState } from 'react';
import { Container, Form, Col, Row, ButtonToolbar, Button, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Toast } from 'primereact/toast';

import { message } from '../shared/Message';
import { licenseLevelStrings, licenseTypeStrings } from '../../constants';
import api from '../../scripts/api';
import { getAnalyticsForOrg } from '../../scripts/api/analyticsApi';
import { updateSettings } from '../../scripts/api/organizationApi';
import ExternalAnalyticsListDto from '../../DTOs/PowerBiEmbedded/ExternalAnalyticsListDto.js';
import OrganizationDto from '../../DTOs/Organization/OrganizationDto.js';
import OrganizationSettingsDto from '../../DTOs/Organization/OrganizationSettingsDto.js';
import useToast from '../shared/hooks/useToast.js';

import OrganizationSettingsDisplay from './OrganizationSettingsDisplay.js';
import ToggleAnalyticsForm from './ToggleAnalyticsForm.js';

function OrganizationSettings() {
  const [isLoading, setIsLoading] = useState(false);
  const [organization, setOrganization] = useState(new OrganizationDto());
  const [externalAnalyticsList, setExternalAnalyticsList] = useState(new ExternalAnalyticsListDto());
  const [defaultAnalyticsId, setDefaultAnalyticsId] = useState(null);
  const [externalAnalyticsDropdownItems, setExternalAnalyticsDropdownItems] = useState([]);
  const currentUser = useSelector(state => state.user);
  const { effectiveOrganizationId } = currentUser;
  const { toast, successToast, errorToast } = useToast()

  useEffect(() => {
    async function getOrganizationByIdAsync(id) {
      const result = await api.getOrganizationById(id);
      setOrganization(result);
      setDefaultAnalyticsId(result.defaultExternalAnalyticsId);
    }

    async function getAnalyticsForOrgAsync() {
      const result = await getAnalyticsForOrg();
      setExternalAnalyticsList(result);
      if (result.externalAnalytics.length > 0) {
        let dropdownItems = [];
        dropdownItems.push({ text: "Default Analytics", value: 0 });

        result.externalAnalytics.forEach((obj) => {
          dropdownItems.push({ text: obj.displayName, value: obj.id });
        })

        setExternalAnalyticsDropdownItems(dropdownItems);
      }
    }

    try {
      getOrganizationByIdAsync(effectiveOrganizationId);
      getAnalyticsForOrgAsync();
    } catch (error) {
      message.error(`Error occurred: ${error}`)
    }
  }, [effectiveOrganizationId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    const dto = new OrganizationSettingsDto({
      defaultAnalyticsId: defaultAnalyticsId === '0' ? null : defaultAnalyticsId,
    });

    try {
      await updateSettings(JSON.stringify(dto));
      successToast("The analytics tab was successfully updated")
    } catch(error) {
      errorToast(`Error occurred while updating the analytics tab: ${error}`)
    } finally {
      setIsLoading(false);
    }
  }

  const handleAnalyticsChange = (e) => {
    setDefaultAnalyticsId(e.target.value);
  }

  return (
    <Container className="basic-card pb-3">
      <OrganizationSettingsDisplay
        organization={organization}
        licenseLevelStrings={licenseLevelStrings}
        licenseTypeStrings={licenseTypeStrings}
      />
      
      { !isLoading && externalAnalyticsList.externalAnalytics.length === 0 ? null :
        <ToggleAnalyticsForm
          handleSubmit={handleSubmit}
          defaultAnalyticsId={defaultAnalyticsId}
          handleAnalyticsChange={handleAnalyticsChange}
          externalAnalyticsDropdownItems={externalAnalyticsDropdownItems}
          isLoading={isLoading}
        />
        }

        <Toast ref={toast} position="bottom-left" />
    </Container>
  );
}

export default OrganizationSettings;
