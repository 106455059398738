export default class ClientGroupWithClientsDto {
    constructor(options = {}) {
        this.id = options.id || 0;
        this.name = options.name || "";
        this.isActive = options.hasOwnProperty('isActive') ? options.isActive : true;
        this.clients = options.clients || [];
    }

    stringify() {
        return JSON.stringify(this);
    }
}