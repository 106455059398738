import React from 'react';
import { ButtonToolbar, Button } from 'react-bootstrap';

function EditServiceActivityButtonToolbar({ handleCancelClick, isSubmitting }) {
    return (
        <ButtonToolbar className="d-flex justify-content-end">
            <Button variant="outline-primary" onClick={handleCancelClick}>Cancel</Button>
            <Button variant="primary" type="submit" disabled={isSubmitting}>Save</Button>
        </ButtonToolbar>
    )
}

export default EditServiceActivityButtonToolbar;