import { useRef } from "react";

const useToast = () => {
    const toast = useRef();

    const successToast = (toastMessage) => {
        toast.current.show({
            severity: "success",
            summary: "Success",
            detail: toastMessage,
            life: 3500
        })
    }

    const errorToast = (toastMessage) => {
        toast.current.show({
            severity: "error",
            summary: "Error",
            detail: toastMessage,
            life: 3500
        })
    }

    return {
        toast,
        successToast,
        errorToast
    }
}

export default useToast;