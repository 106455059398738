import React, { useEffect, Suspense, lazy } from 'react';
import { Spinner } from 'react-bootstrap';
import { Route, Routes, useLocation } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useAuth0 } from './components/shared/Auth0';
import PrivateComponent from './components/shared/PrivateRoute';
import Layout from './components/shared/Layout';
import { HomeRedirect } from './components/login/HomeRedirect';
import LoginCallback from './components/login/LoginCallback';
import Privacy from './components/shared/Privacy';
import { actionCreators } from './store/User';

import ServiceIndex from './components/services/ServiceIndex';
import Service from './components/services/Service';
import ServiceAdd from './components/services/ServiceAdd';
import ServiceImport from './components/services/import/ServiceImport'

import StaffIndex from './components/staff/StaffIndex';
import Staff from './components/staff/Staff';
import StaffAdd from './components/staff/StaffAdd';

import DemographicsBuilderPage from './components/demographicsBuilder/DemographicsBuilderPage';

import OrganizationIndex from './components/organization/OrganizationIndex.js';
import OrganizationAdd from './components/organization/OrganizationAdd.js';
import UpdateInvalidDataIndex from './components/organization/UpdateInvalidDataIndex.js';
import UpdateInvalidDataRecord from './components/organization/UpdateInvalidDataRecord';
import Organization from './components/organization/Organization.js';
import OrganizationSettings from './components/organization/OrganizationSettings.js';

import InactiveOrganization from './components/shared/InactiveOrganization';
import ServiceHoursIndex from './components/organization/ServiceHoursIndex';
import CostPerSuccessIndex from './components/program/CostPerSuccessIndex';
import ReportsIndex from './components/analytics/AnalyticsReportIndex';

import MeasuresIndex from './components/measures/MeasuresIndex';
import MeasureAdd from './components/measures/MeasureAdd';
import Measure from './components/measures/Measure';

import NotFound from './components/shared/NotFound.js';

import ExternalAnalyticsIndex from './components/analytics/ExternalAnalyticsIndex';
import ExternalAnalyticsPage from './components/analytics/ExternalAnalyticsPage';

const Dashboard = lazy(() => import('./components/dashboard/Dashboard'));
const ClientPage = lazy(() => import('./components/clients/ClientPage'));
const ProgramPage = lazy(() => import('./components/program/ProgramPage'));

const App = (props) => {
  const auth0Context = useAuth0();
  const location = useLocation();

  useEffect(() => {
    if (auth0Context.isAuthenticated) {
      props.initializeApp();
    }
  }, [auth0Context.isAuthenticated]);

  if (props.roles.includes("IsDisabled")) {
    return (
      <Layout location={location}>
        <Route path="/" element={<HomeRedirect />} exact />
        <Route path="/callback" element={<LoginCallback />} />
        <Route path="/Home/Privacy" element={<Privacy />} />
        <Route path="/InactiveOrganization" element={<InactiveOrganization />} />
      </Layout>
    );
  }

  return (
    <Layout location={location}>
      <Routes>
        <Route path="/" element={<HomeRedirect />} />
        <Route path="/callback" element={<LoginCallback />} />
        <Route path="/Home/Privacy" element={<Privacy />} />
        <Route path="/Dashboard" element={
          <Suspense fallback={<Spinner animation="border" className="sureimpact__spinner_export" />}>
            <PrivateComponent.AnalyticsUser promptLogin component={Dashboard} />
          </Suspense>
        } />

        <Route path="/Client/*" element={
          <Suspense fallback={<Spinner animation="border" className="sureimpact__spinner_export" />}>
            <PrivateComponent.Staff promptLogin component={ClientPage} />
          </Suspense>
        } />

        <Route path="/ExternalAnalytics" element={<PrivateComponent.RootAdmin promptLogin component={ExternalAnalyticsIndex} />} />
        <Route path="/ExternalAnalytics/:id" element={<PrivateComponent.RootAdmin promptLogin component={ExternalAnalyticsPage} />} />

        <Route path="/Staff" element={<PrivateComponent.Manager promptLogin component={StaffIndex} />} />
        <Route path="/Staff/AddStaff" element={<PrivateComponent.Manager promptLogin component={StaffAdd} />} />
        <Route path="/Staff/:id" element={<PrivateComponent.Staff promptLogin component={Staff} />} />

        <Route path="/Program/*" element={
          <Suspense fallback={<Spinner animation="border" className="sureimpact__spinner_export" />}>
            <PrivateComponent.Manager promptLogin component={ProgramPage} />
          </Suspense>
        } />

        <Route path="/DemographicsBuilder" element={<PrivateComponent.PaidAdmin promptLogin component={DemographicsBuilderPage} />} />

        <Route path="/Organization" element={<PrivateComponent.RootAdmin promptLogin component={OrganizationIndex} />} />
        <Route path="/Organization/AddOrganization" element={<PrivateComponent.RootAdmin promptLogin component={OrganizationAdd} />} />
        <Route path="/Organization/UpdateInvalidDataIndex" element={<PrivateComponent.RootAdmin promptLogin component={UpdateInvalidDataIndex} />} />
        <Route path="/Organization/UpdateInvalidData/:id" element={<PrivateComponent.RootAdmin promptLogin component={UpdateInvalidDataRecord} />} />
        <Route path="/Organization/:id" element={<PrivateComponent.RootAdmin promptLogin component={Organization} />} />
        <Route path="/Organization/Settings" element={<PrivateComponent.Admin promptLogin component={OrganizationSettings} />} />

        <Route path="/OrganizationService" element={<PrivateComponent.Staff promptLogin component={ServiceIndex} />} />
        <Route path="/OrganizationService/AddService" element={<PrivateComponent.Manager promptLogin component={ServiceAdd} />} />
        <Route path="/OrganizationService/Import" element={<PrivateComponent.Manager promptLogin component={ServiceImport} />} />
        <Route path="/OrganizationService/:id" element={<PrivateComponent.Staff promptLogin component={Service} />} />

        <Route path="/ServiceHours" element={<PrivateComponent.AnalyticsUser promptLogin component={ServiceHoursIndex} />} />
        <Route path="/CostPerSuccess" element={<PrivateComponent.AnalyticsUser component={CostPerSuccessIndex} />} />
        <Route path="/Reports/" element={<PrivateComponent.AnalyticsUser component={ReportsIndex} />} />
        <Route path="/Reports/:id" element={<PrivateComponent.AnalyticsUser component={ReportsIndex} />} />

        <Route path="/Measures" element={<PrivateComponent.Manager component={MeasuresIndex} />} />
        <Route path="/Measures/AddMeasure" element={<PrivateComponent.Admin component={MeasureAdd} />} />
        <Route path="/Measures/:id" element={<PrivateComponent.Manager component={Measure} />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Layout>
  );
}

export default connect(
  state => state.user,
  dispatch => bindActionCreators(actionCreators, dispatch)
)(App);
