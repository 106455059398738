import React from 'react';
import { Form, Col } from 'react-bootstrap';
import { Field } from 'formik';

import MultiSelect from '../../shared/asyncSelect/MultiSelect';

function ClientsAndStaffForServiceForm({ values, handleChange, searchClients, pageNum, pageSize, touched, errors, ...props }) {
    return (
        <Form.Row>
            { Number(props.currentGroupId) === 0 ?
                <Form.Group as={Col} controlId="additionalClients">
                    <Form.Label>
                        Add one or more clients to this Service Activity
                    </Form.Label>
                    <Field name="additionalClients" value={values.additionalClients}>
                        {({ field, form }) => (
                            <MultiSelect
                                placeholder="Search clients"
                                noOptionsMessage={() => 'No clients found'}
                                closeMenuOnSelect={false}
                                loadOptions={searchClients}
                                additional={{ pageNum: pageNum, pageSize: pageSize }}
                                onChange={(selectedClients) => form.setFieldValue(field.name, (selectedClients || []).map(c => c.value))}
                            />
                        )}
                    </Field>
                    <Form.Control.Feedback type="invalid" className={touched.additionalClients && !!errors.additionalClients ? 'd-block' : null}>
                        {errors.additionalClients}
                    </Form.Control.Feedback>
                </Form.Group>
            : null }

            <Form.Group as={Col}>
                <Form.Label>Assign a staff to this Service Activity</Form.Label>
                <Form.Control
                    as="select"
                    placeholder='Select staff to assign'
                    className={`sure-impact__select ${values.applicationUserId === '' ? 'sure-impact__select-default' : ''}`}
                    value={values.applicationUserId}
                    name="applicationUserId"
                    onChange={handleChange}
                    isInvalid={touched.applicationUserId && !!errors.applicationUserId}
                >
                    {props.checkIfCurrentUserIsSiteAdmin &&
                        <option value="" className="select-default">Select User</option>
                    }
                    {props.userList.length === 0 ?
                        <option value="">Loading Users...</option>
                        :
                        props.userList.map(user => (
                            <option key={user.value} value={user.value}>
                                {user.label}
                            </option>
                        ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                    {errors.applicationUserId}
                </Form.Control.Feedback>
            </Form.Group>
        </Form.Row>
    )
}

export default ClientsAndStaffForServiceForm
