export default class ClientGroupDto {
    constructor(options = {}) {
        this.id = options.id || 0;
        this.name = options.name || "";
        this.isActive = options.hasOwnProperty('isActive') ? options.isActive : true;
    }

    stringify() {
        return JSON.stringify(this);
    }
}