import React from 'react';
import { Form, Col } from 'react-bootstrap';
import InputMask from 'react-input-mask';

function EditServiceDurationForm({ handleChange, values, touched, errors }) {
    return (
        <>
            <Form.Row>
                <Form.Group as={Col} lg={2} md={3} sm={4} xs={6} controlId="durationHours">
                    <Form.Label>Duration</Form.Label>
                    <Form.Control
                        as="select"
                        className={`sure-impact__select ${values.durationHours === '' ? 'sure-impact__select-default' : ''}`}
                        value={values.durationHours}
                        onChange={handleChange}
                        isInvalid={touched.durationHours && !!errors.HrsOrMins}
                    >
                        <option value="">Hours</option>
                        {Array.from({ length: 25 }, (_, i) => (
                            <option key={i} value={i}>{i}</option>
                        ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                        {errors.HrsOrMins}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} lg={2} md={3} sm={4} xs={6} controlId="durationMinutes">
                    <Form.Label aria-label="Duration - Minutes">&nbsp;</Form.Label>
                    <Form.Control
                        as="select"
                        className={`sure-impact__select ${values.durationMinutes === '' ? 'sure-impact__select-default' : ''}`}
                        value={values.durationMinutes}
                        onChange={handleChange}
                        isInvalid={touched.durationMinutes && !!errors.HrsOrMins}
                    >
                        <option value="" defaultValue>Minutes</option>

                        {[...Array(60).keys()].map(value => {
                            return <option key={value} value={value}>{value}</option>;
                        })}

                    </Form.Control>
                </Form.Group>

                <Col xs={1} />

                <Form.Group as={Col} lg={2} md={3} sm={4} xs={6} controlId="activityDate">
                    <Form.Label>Service Date</Form.Label>
                    <InputMask mask="99/99/9999" value={values.activityDate} onChange={handleChange} touched={touched} errors={errors}>
                        <Form.Control
                            type="text"
                            value={values.activityDate}
                            onChange={handleChange}
                            isInvalid={touched.activityDate && !!errors.activityDate}
                        />
                    </InputMask>
                    <Form.Control.Feedback type="invalid">
                        {errors.activityDate}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} lg={2} md={3} sm={4} xs={6} controlId="activityTime">
                    <Form.Label>Service Time</Form.Label>
                    <InputMask mask={[/[0-1]/, /[0-9]/, ':', /[0-5]/, /[0-9]/, ' ', /[AaPp]/, /[Mm]/]} maskPlaceholder="hh:mm tt" value={values.activityTime} onChange={handleChange} touched={touched} errors={errors}>
                        <Form.Control
                            type="text"
                            value={values.activityTime}
                            onChange={handleChange}
                            isInvalid={touched.activityTime && !!errors.activityTime}
                        />
                    </InputMask>
                    <Form.Control.Feedback type="invalid">
                        {errors.activityTime}
                    </Form.Control.Feedback>
                </Form.Group>

            </Form.Row>

            <Form.Row>
                <Form.Group as={Col} controlId="notes">
                    <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Notes"
                        value={values.notes}
                        onChange={handleChange}
                        isInvalid={touched.notes && !!errors.notes}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.notes}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form.Row>
        </>
    )
}
export default EditServiceDurationForm;
