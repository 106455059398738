import { apiCall } from "../api";
import EmbedParametersDto from '../../DTOs/PowerBiEmbedded/EmbedParametersDto';
import ExternalAnalyticsListDto from '../../DTOs/PowerBiEmbedded/ExternalAnalyticsListDto';
import ExternalAnalyticsDto from "../../DTOs/PowerBiEmbedded/ExternalAnalyticsDto";

export const getEmbedParameters = async (id) => {
  let url = 'externalanalytics/EmbedParameters/';

  if (id !== undefined) {
    url = `externalanalytics/EmbedParameters/${id}`;
  }

  let response = await apiCall(url);
  return new EmbedParametersDto(response.result);
}

export const getAnalyticsById = async (id) => {
  let url = `externalanalytics/${id}`;
  let response = await apiCall(url);
  return new ExternalAnalyticsDto(response.result);
}

export const getAnalyticsForOrg = async () => {
  let url = 'externalanalytics/';
  let response = await apiCall(url);
  return new ExternalAnalyticsListDto(response.result);
}

export const addAnalytics = async (dto) => {
  const url = 'externalanalytics/add';
  let response = await apiCall(url, { method: 'POST', body: dto });
  return new ExternalAnalyticsDto(response.result);
}

export const updateAnalytics = async (dto) => {
  const url = 'externalanalytics/update';
  let response = await apiCall(url, { method: 'PUT', body: dto });
  return new ExternalAnalyticsDto(response.result);
}

export const deleteAnalytics = async (id) => {
  const url = `externalanalytics/delete/${id}`;
  let response = await apiCall(url, { method: 'DELETE' });
  return response;
}
