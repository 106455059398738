import React, { useState, useCallback } from "react";
import UpdateClientGroupDto from "../../../DTOs/ClientGroup/UpdateClientGroupDto";
import {getAllClientGroups, getClientGroupById, updateClientGroup } from "../../../scripts/api/clientGroupsApi";
import { message } from "../../shared/Message";
import ClientGroupWithClientsDto from "../../../DTOs/ClientGroup/ClientGroupWithClientsDto";
import ClientGroupListDto from "../../../DTOs/ClientGroup/ClientGroupListDto";

const useClientGroupActions = () => {
    const [pagedGroupsState, setPagedGroupsState] = useState(new ClientGroupListDto());
    const [clientGroup, setClientGroup] = useState(new ClientGroupWithClientsDto());
    const [isLoading, setIsLoading] = useState(false);

    const fetchClientGroupList = async (pageIndex, pageSize) => {
        setIsLoading(true);
        let result;
        try {
            result = await getAllClientGroups(pageIndex, pageSize);
        } catch (error) {
            message.error(`Error occurred while fetching client groups: ${error}`);
        } finally {
            setIsLoading(false);
        }

        setPagedGroupsState(result);
    }

    const fetchActiveClientGroupList = async (pageIndex, pageSize) => {
        setIsLoading(true);
        let result;
        try {
            result = await getAllClientGroups(pageIndex, pageSize);
        } catch (error) {
            message.error(`Error occurred while fetching client groups: ${error}`);
        } finally {
            setIsLoading(false);
        }

        const filteredResults = {
            ...result,
            allClientGroups: result.allClientGroups.filter(clientGroup => clientGroup.isActive)
        }

        setPagedGroupsState(filteredResults);
    }

    const handlePageClick = (newPage) => {
        setPagedGroupsState(prevState => ({
            ...prevState,
            pageIndex: newPage - 1
        }))
    }

    const fetchClientGroup = useCallback(async (groupId) => {
        setIsLoading(true);
        let result;

        try {
            result = await getClientGroupById(groupId);
        } catch (error) {
            message.error(`Error occurred while fetching client group: ${error}`);
        } finally {
            setIsLoading(false);
        }

        setClientGroup(result);
    }, []);

    const handleUpdateClientGroup = async (values, clientList) => {
        const groupDto = new UpdateClientGroupDto({
            id: values.id,
            name: values.name,
            isActive: values.isActive,
            clientIds: clientList.map(client => client.value)
        })

        try {
            await updateClientGroup(groupDto.stringify());
            fetchClientGroup(groupDto.id);
        } catch (error) {
            message.error(`Error occurred while updating Client Group: ${error}`)
        }
    }

    return {
        pagedGroupsState,
        clientGroup,
        isLoading,
        fetchClientGroupList,
        fetchActiveClientGroupList,
        handlePageClick,
        fetchClientGroup,
        handleUpdateClientGroup
    };
}

export default useClientGroupActions;