import React, { useState, useEffect } from "react";

import useFetchApplicationUsers from "../../../shared/hooks/useFetchApplicationUsers";

const useServiceActivityUsers = (currentUser, serviceActivity) => {
    const [applicationUsers, setApplicationUsers] = useState([]);
    const { fetchActiveStaff } = useFetchApplicationUsers();

    const setAvailableUsers = async () => {
        let userList = await fetchActiveStaff();
        let loggedInUser = {
            label: `${currentUser.firstName} ${currentUser.lastName}`,
            value: currentUser.id
        };

        if (currentUser.roles[0] !== "SiteAdministrator") {
            userList = userList.filter(user => user.value !== loggedInUser.value)
            userList = [loggedInUser, ...userList];
        }

        if (serviceActivity && serviceActivity.applicationUserId > 0) {
            let defaultUser = {
                label: serviceActivity.staffName,
                value: serviceActivity.applicationUserId
            }

            userList = userList.filter(user => user.value !== defaultUser.value)
            userList = [defaultUser, ...userList]
        }

        setApplicationUsers(userList);
    }

    useEffect(() => {
        setAvailableUsers();
    }, [currentUser, serviceActivity])

    return { applicationUsers };
}

export default useServiceActivityUsers;