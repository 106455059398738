import { useState } from 'react';
import api from '../../../scripts/api';
import { message } from '../Message';

const useFetchApplicationUsers = () => {
    const fetchActiveStaff = async () => {
        try {
            let response = await api.getAllActiveStaff();
            return response.applicationUsers.map((user) => ({
                label: `${user.firstName} ${user.lastName}`,
                value: user.id
            }));
        } catch (error) {
            message.error(`Error fetching users for assignment: ${error}`)
        }
    }

    const fetchStaffById = async (staffId) => {
        try {
            let response = await api.getStaffById(staffId);
            return response;
        } catch (error) {
            message.error(`Error fetching staff for assignment: ${error}`)
        }
    }

    return {
        fetchActiveStaff,
        fetchStaffById,
    };
};

export default useFetchApplicationUsers;