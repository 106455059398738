import ClientGroupDto from "./ClientGroupDto";

export default class ClientGroupListDto {
    constructor(options = {}) {
        this.allClientGroups = options.allClientGroups?.map(dto => new ClientGroupDto(dto)) || [];
        this.pageIndex = options.pageIndex || 0;
        this.pageSize = options.pageSize || 10;
        this.totalMatches = options.totalMatches || 0;
    }

    stringify() {
        return JSON.stringify(this);
    }
}