import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import Loading from "../shared/Loading";

function ExternalAnalyticsDataTable({ listData, handleRowClick, pageSize, isLoading }) {
    return (
        <>
            {isLoading
                ? <Loading data={"Analytics"} />
                : (
                    <DataTable
                        value={listData}
                        stripedRows
                        rows={pageSize}
                        emptyMessage="No analytics found"
                        onRowClick={(e) => handleRowClick(e)}
                        className="client-data-table"              
                    >
                        <Column field="displayName" header="Name" />
                        <Column field="externalId" header="External ID" />
                        <Column field="replacesDefaultAnalytics" header="Replaces Default Analytics" />
                    </DataTable>
                )
            }
        </>
    )
}

export default ExternalAnalyticsDataTable;
