import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { getAnalyticsForOrg } from '../../scripts/api/analyticsApi';
import { message } from '../shared/Message';
import { useNavigate } from "react-router";

import { Toast } from 'primereact/toast';
import useToast from '../shared/hooks/useToast';

import ExternalAnalyticsListDto from '../../DTOs/PowerBiEmbedded/ExternalAnalyticsListDto';
import ExternalAnalyticsDataTable from './ExternalAnalyticsDataTable';
import AddExternalAnalytics from './AddExternalAnalytics';

function ExternalAnalyticsIndex() {
  const [isLoading, setIsLoading] = useState(false);
  const [externalAnalyticsList, setExternalAnalyticsList] = useState(new ExternalAnalyticsListDto());
  const [showAddModal, setShowAddModal] = useState(false);
  const navigate = useNavigate();
  const { toast } = useToast();

  const handleRowClick = (event => {
    const { id } = event.data;
    navigate(`/ExternalAnalytics/${id}`);
  });

  const handleAddClick = () => {
    setShowAddModal(true);
  };

  const fetchAnalytics = async () => {
    try {
      setIsLoading(true);
      let response = await getAnalyticsForOrg();
      setExternalAnalyticsList(response);
      setIsLoading(false);
    }
    catch (err) {
      message.error(`Error getting report: ${err.message}`);
    }
  }

  useEffect(() => {
    fetchAnalytics();
  }, []);

  return (
      <React.Fragment>

        <Row className="my-3">
          <Col md={6} className="align-self-end">
          <h5>External Analytics</h5>
          </Col>
          <Col md={6} className="d-flex justify-content-end">
            <Button onClick={handleAddClick}>Add</Button>
          </Col>
        </Row>

      <Row className="mb-2">
        {externalAnalyticsList ?
          <ExternalAnalyticsDataTable
            isLoading={isLoading}
            listData={externalAnalyticsList.externalAnalytics}
            handleRowClick={handleRowClick}
          /> :
          null
        }
      </Row>

      {showAddModal ?
        <AddExternalAnalytics
          showModal={showAddModal}
          hideModal={() => setShowAddModal(false)}
          setIsLoading={setIsLoading}
          handleRefresh={fetchAnalytics}
        />
        : null}

      <Toast ref={toast} position="bottom-left" />
      </React.Fragment>
  );
}

export default ExternalAnalyticsIndex;
