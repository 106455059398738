import React, { useState } from 'react';
import { Button, ButtonToolbar, Form, Modal, Spinner } from 'react-bootstrap';
import ExternalAnalyticsDto from '../../DTOs/PowerBiEmbedded/ExternalAnalyticsDto';
import { updateAnalytics } from '../../scripts/api/analyticsApi';

function EditExternalAnalytics({ showModal, hideModal, externalAnalytics, handleRefresh, handleErrorMessage, ...props }) {
  const [isLoading, setIsLoading] = useState(false);
  const [displayName, setDisplayName] = useState(externalAnalytics.displayName);
  const [externalId, setExternalId] = useState(externalAnalytics.externalId);
  const [replacesDefaultAnalytics, setReplacesDefaultAnalytics] = useState(externalAnalytics.replacesDefaultAnalytics);

  const handleDisplayNameChange = (e) => {
    setDisplayName(e.target.value);
  }

  const handleExternalIdChange = (e) => {
    setExternalId(e.target.value);
  }

  const handleReplacesDefaultAnalyticsChange = (e) => {
    setReplacesDefaultAnalytics(e.target.checked);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!displayName) {
      handleErrorMessage("Display Name cannot be empty");
      return;
    }

    if (!externalId) {
      handleErrorMessage("External ID cannot be empty");
      return;
    }

    setIsLoading(true);

    const dto = new ExternalAnalyticsDto({
      ...externalAnalytics,
      displayName: displayName,
      externalId: externalId,
      replacesDefaultAnalytics: replacesDefaultAnalytics
    });

    try {
      await updateAnalytics(JSON.stringify(dto));
      hideModal();
      setIsLoading(false);
      handleRefresh(externalAnalytics.id)
    } catch (error) {
      setIsLoading(false);
      handleErrorMessage(`Error updating external analytics: ${error}`)
    }
  }

  return (
    <Modal
      show={showModal}
      onHide={hideModal}
      centered
    >
      <Modal.Header className="modal__header">
        <span>Edit External Analytics</span>
        <span
          className="modal__header__btn"
          onClick={hideModal}
          aria-label="Exit"
        >
          X
        </span>
      </Modal.Header>

      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Label htmlFor="displayName">Display Name</Form.Label>
          <Form.Control
            type="text"
            value={displayName}
            name="displayName"
            onChange={handleDisplayNameChange}
            isInvalid={props.errorMessage}
          />
          <Form.Label htmlFor="externalId">External ID</Form.Label>
          <Form.Control
            type="text"
            value={externalId}
            name="externalId"
            onChange={handleExternalIdChange}
            isInvalid={props.errorMessage}
          />

          <Form.Check
            label="Replaces Default Analytics"
            checked={replacesDefaultAnalytics}
            name="replacesDefaultAnalytics"
            onChange={handleReplacesDefaultAnalyticsChange} />

          <Form.Control.Feedback type="invalid">{props.errorMessage}</Form.Control.Feedback>

          <ButtonToolbar className="mt-4">
            <Button variant="outline-primary" onClick={hideModal}>Cancel</Button>
            <Button variant="primary" disabled={props.isLoading} type="submit">
              {isLoading ? (
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              ) : (
                "Save"
              )}
            </Button>
          </ButtonToolbar>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default EditExternalAnalytics
