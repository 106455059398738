import React, { useState, useEffect } from "react";

import useClientGroupActions from "../../../clientGroups/hooks/useClientGroupActions";
import usePickListUpdate from "../../../shared/hooks/usePickListUpdate";

const useFetchGroupsForServices = (allowGroups) => {
    const [currentGroupId, setCurrentGroupId] = useState(0);
    const { pagedGroupsState, clientGroup, fetchActiveClientGroupList, fetchClientGroup } = useClientGroupActions();
    const { selected, setSelectedList, available, addToSelected, removeFromSelected } = usePickListUpdate();

    const updateGroupId = (event) => {
        setCurrentGroupId(event.target.value)
    }

    useEffect(() => {
        if (currentGroupId > 0) {
            fetchClientGroup(currentGroupId);
        }
    }, [currentGroupId])

    useEffect(() => {
        const clientsInGroup = clientGroup?.clients.map(client => ({
            value: client.id,
            label: `${client.firstName} ${client.lastName}`
        }))

        setSelectedList(clientsInGroup)
    }, [clientGroup])

    useEffect(() => {
        if (allowGroups) {
            fetchActiveClientGroupList(pagedGroupsState.pageIndex)
        }
    }, [])

    return {
        currentGroupId,
        updateGroupId,
        pagedGroupsState,
        selected,
        available,
        addToSelected,
        removeFromSelected
    }
}

export default useFetchGroupsForServices;