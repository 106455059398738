import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const NotFound = ({redirectLink}) => {
    const navigate = useNavigate();

    const handleHomeRedirect = () => {
        if (redirectLink) {
            navigate(redirectLink);
        } else {
            navigate('/');
        }
    };

    return (
        <Container className="d-flex justify-content-center align-items-center vh-100">
            <Row className="text-center">
                <Col>
                    <h1>404 - Page Not Found</h1>
                    <p>The page you are looking for does not exist.</p>
                    <Button variant="primary" onClick={handleHomeRedirect}>Go Back</Button>
                </Col>
            </Row>
        </Container>
    );
};

export default NotFound;